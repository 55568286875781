<template>
  <v-container fluid class="mb-5 mt-3">
    <v-row class="m-2">
      <v-col cols="12" lg="7" sm="7" md="7">
        <v-card color="teal darken-1 mb-2" dark>
          <v-card-text>
            <v-row class="mt-1 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-1 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-divider></v-divider>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="formSuratSakit.noSurat"
            label="No. Surat"
            outlined
            clearable
            dense
            readonly
          ></v-text-field>
          <v-radio-group v-model="formSuratSakit.karena" :rules="karena" row>
            <template v-slot:label>
              <div><b>Karena</b></div>
            </template>
            <v-radio value="Sakit">
              <template v-slot:label>
                <div>
                  <strong class="success--text">Sakit</strong>
                </div>
              </template>
            </v-radio>
            <v-radio value="Cuti">
              <template v-slot:label>
                <div>
                  <strong class="primary--text">Cuti</strong>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
          <v-text-field
            v-model="formSuratSakit.tgl_mulai"
            label="TANGGAL MULAI"
            prepend-icon="mdi-calendar"
            type="date"
            :rules="tgl_mulai"
            @blur="menghitungSelisihTanggal()"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="formSuratSakit.tgl_selesai"
            label="TANGGAL SELESAI"
            prepend-icon="mdi-calendar"
            type="date"
            :rules="tgl_selesai"
            @blur="menghitungSelisihTanggal()"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="formSuratSakit.selama"
            label="Selama"
            :rules="selama"
            class="mt-2"
            outlined
            clearable
            dense
          ></v-text-field>
          <v-btn
            color="primary"
            v-if="!!regid"
            class="btn btn-block"
            @click="simpanSuratSakit"
            dark
            >Simpan Surat Sakit</v-btn
          >
        </v-form>
      </v-col>
      <v-col cols="12" lg="5" sm="5" md="5">
        <v-card>
          <v-card-title>List Surat Sakit 👩🏻‍⚕️</v-card-title>

          <v-card-text>
            <div class="list-group m-1" v-for="(itemListSurat,indexListSurat) of listSurat" :key="indexListSurat">
              <a
                href="#"
                class="list-group-item list-group-item-action flex-column align-items-start mt-1"
              >
                <div class="d-flex w-100 justify-content-between">
                  <h5 class="mb-1">{{itemListSurat.no_surat}}</h5>
                  <small class="text-muted">{{itemListSurat.created_at}}</small>
                </div>
                <p class="mb-1">
                  <b>Jenis Surat :</b> {{ itemListSurat.karena }}
                </p>
                <v-btn class="btn btn-block" @click="printSuratSakit(itemListSurat.id_surat_sakit)" color="primary" medium>Liat Surat Sakit</v-btn>
              </a>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  baseUrlEmr
} from '../../plugins/supports'
import Master from '../../data/surat-sakit'
import SmartWidget from '@/components/SmartWidget.vue'
import * as moment from 'moment'
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SmartWidget
  },
  data () {
    return {
      valid: true,

      radios: 'Sakit',
      master: Master,
      listSurat: [],
      regid: this.$route.query.registration_id,
      formSuratSakit: {
        noSurat: '',
        karena: 'Sakit',
        tgl_mulai: '',
        tgl_selesai: '',
        selama: ''
      },
      tgl_mulai: [(v) => !!v || 'Tanggal Mulai Tidak Boleh Kosong'],
      tgl_selesai: [(v) => !!v || 'Tanggal Selesai Tidak Boleh Kosong'],
      selama: [(v) => !!v || 'Hari Sakit Tidak Boleh Kosong'],
      karena: [(v) => !!v || 'Alasan Sakit Tidak Boleh Kosong'],
      pegawai: '',
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          jenis_kelamin: '',
          cara_bayar: '',
          reg_id: '',
          folio_id: ''
        }
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        }
      }
    }
  },
  created () {
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.no_rm)
      this.getNoSurat(this.$route.query.sub_unit_id)
      this.getListSuratSakit()
      this.pegawai = JSON.parse(localStorage.getItem('user'))
    }
  },
  methods: {
    getDataPasien (v) {
      apiKedua
        .get('emr/detail-pasien?rm=' + v, {})
        .then((response) => {
          this.dataPatient = response.data.results

          this.data.demografi = this.dataPatient
          this.data.demografi.jenis_kelamin = {
            value: this.dataPatient.jenis_kelamin,
            text: this.dataPatient.jenis_kelamin
          }
          this.data.demografi.folio_id = this.$route.query.folio_id
          this.data.demografi.reg_id = this.$route.query.registration_id
          this.data.demografi.sub_unit_id = this.$route.query.sub_unit_id

          this.patient_name = response.data.results.nama_pasien
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getNoSurat () {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 +
          'surat-sakit/get-no-surat?sub_unit_id=' +
          this.$route.query.sub_unit_id,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.formSuratSakit.noSurat = results
          } else {
            // errorMsg(msg)
          }
        },
        'JSON'
      )
    },
    getListSuratSakit () {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 +
          'surat-sakit/list-surat-sakit?no_rm=' +
          this.$route.query.no_rm,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.listSurat = results
          } else {
            // errorMsg(msg)
          }
        },
        'JSON'
      )
    },
    menghitungSelisihTanggal () {
      // eslint-disable-next-line no-unused-vars
      var tglMulai = this.formSuratSakit.tgl_mulai
      // eslint-disable-next-line no-unused-vars
      var tglSelesai = this.formSuratSakit.tgl_selesai
      // eslint-disable-next-line no-unused-vars

      var tglaw = moment(tglMulai)
      var tglak = moment(tglSelesai)
      // eslint-disable-next-line no-unused-vars
      var numYears, numMonths, numDays, hasil

      numYears = tglak.diff(tglaw, 'years')
      tglaw = tglaw.add(numYears, 'years')
      numMonths = tglak.diff(tglaw, 'months')
      tglaw = tglaw.add(numMonths, 'months')
      numDays = tglak.diff(tglaw, 'days')

      if (numYears === 0 && numMonths === 0) {
        if (numDays === 0) {
          hasil = '1 Hari'
        } else {
          hasil = numDays + ' Hari'
        }
      } else if (numYears === 0 && numMonths !== 0) {
        if (numDays === 0) {
          hasil = numMonths + ' Bulan'
        } else {
          hasil = numMonths + ' Bulan, ' + numDays + ' Hari'
        }
      } else if (numYears !== 0) {
        if (numMonths === 0 && numDays === 0) {
          hasil = numYears + ' Tahun'
        } else if (numMonths !== 0 && numDays === 0) {
          hasil = numYears + ' Tahun, ' + numMonths + ' Bulan'
        } else if (numMonths === 0 && numDays !== 0) {
          hasil = numYears + ' Tahun, ' + numDays + ' Hari'
        } else if (numMonths !== 0 && numDays !== 0) {
          hasil =
            numYears + ' Tahun, ' + numMonths + ' Bulan, ' + numDays + ' Hari'
        }
      }
      this.formSuratSakit.selama = hasil

      console.log(hasil)
    },
    simpanSuratSakit () {
      // eslint-disable-next-line no-unused-vars

      if (this.$refs.form.validate()) {
        // eslint-disable-next-line no-undef
        Swal.fire({
          title: 'Perhatian! Apakah Anda Yakin?',
          text: 'Ingin Membuat Surat Sakit??',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya, Buat Surat Sakit!',
          cancelButtonText: 'Tidak Jadi Batal',
          html: ''
        }).then((result) => {
          if (result.value) {
            const reg = {
              reg_id: this.$route.query.registration_id,
              sub_unit_id: this.$route.query.sub_unit_id,
              folio_id: this.$route.query.folio_id,
              employee_id: this.pegawai.employee_id
            }

            // eslint-disable-next-line no-unused-vars
            const data = {
              reg: reg,
              data: this.formSuratSakit
            }

            // eslint-disable-next-line no-undef
            $.post(
              baseUrlV2 + 'surat-sakit/simpan-surat-sakit',
              data,
              (res) => {
                // eslint-disable-next-line no-unused-vars
                const { con, msg, results } = res
                if (con) {
                  successMsg(msg)
                  // this.formSuratSakit.noSurat = ''
                  this.formSuratSakit.karena = 'Sakit'
                  this.formSuratSakit.tgl_mulai = ''
                  this.formSuratSakit.tgl_selesai = ''
                  this.formSuratSakit.selama = ''
                  this.getNoSurat()
                  this.getListSuratSakit()
                } else {
                  errorMsg(msg + ' ' + JSON.stringify(results))
                }
              },
              'JSON'
            )
            console.log(data)
          } else {
            errorMsg('Nggak Jadi Deh !!!')
          }
        })
      }
    },
    printSuratSakit (idsurat) {
      window
        .open(
          baseUrlEmr +
            `laporan-medis/cetak-surat-sakit?id_surat_sakit=${idsurat}` + '&id_reg=' + this.$route.query.registration_id,
          '_blank'
        )
        .focus()
    }
  }
}
</script>
