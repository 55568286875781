export default {

  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      col: 4
    },
    no_rekam_medis: {
      label: 'No.Rekam Medis',
      widget: 'wtext',
      data: null,
      col: 4
    },
    tanggal_lahir: { widget: 'wdate', data: null, col: 3 },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: null,
      col: 4
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      col: 2,
      label: 'No Folio'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      label: 'No Reg',
      col: 5
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'Laki-Laki' },
        { text: 'Perempuan', value: 'Perempuan' }
      ],
      col: 12
    }
  }

  // input_soap: {
  //   s_: {
  //     s_: {
  //       label: 'S (SOAP)',
  //       widget: 'wtextarea',
  //       data: null,
  //       col: 12
  //     }
  //   },
  //   a_: {
  //     a_: {
  //       label: 'A (SOAP)',
  //       widget: 'wtextarea',
  //       data: null,
  //       col: 12
  //     }
  //   },
  //   p_: {
  //     p_: {
  //       label: 'P (SOAP)',
  //       widget: 'wtextarea',
  //       data: null,
  //       col: 12
  //     }
  //   },
  //   o_emt: {
  //     emt_1: {
  //       widget: 'wtext',
  //       data: null,
  //       label: 'EMT 1',
  //       col: 6
  //       // property: { attrs: { suffix: '°C', type: 'number' } }
  //     },
  //     emt_2: {
  //       widget: 'wtext',
  //       data: null,
  //       label: 'EMT 2',
  //       col: 6
  //       // property: { attrs: { suffix: '°C', type: 'number' } }
  //     },
  //     emt_3: {
  //       widget: 'wtext',
  //       data: null,
  //       label: 'EMT 3',
  //       col: 6
  //       // property: { attrs: { suffix: '°C', type: 'number' } }
  //     },
  //     emt_4: {
  //       widget: 'wtext',
  //       data: null,
  //       label: 'EMT 4',
  //       col: 6
  //       // property: { attrs: { suffix: '°C', type: 'number' } }
  //     },
  //     ket_emt: {
  //       widget: 'wtextarea',
  //       data: null,
  //       label: 'Keterangan EMT',
  //       col: 12
  //       // property: { attrs: { suffix: '/ mmHg' } }
  //     }
  //   },
  //   o_rom: {
  //     rom_1: {
  //       widget: 'wtext',
  //       data: null,
  //       label: 'ROM 1',
  //       col: 6
  //       // property: { attrs: { suffix: '°C', type: 'number' } }
  //     },
  //     rom_2: {
  //       widget: 'wtext',
  //       data: null,
  //       label: 'ROM 2',
  //       col: 6
  //       // property: { attrs: { suffix: '°C', type: 'number' } }
  //     },
  //     rom_3: {
  //       widget: 'wtext',
  //       data: null,
  //       label: 'ROM 3',
  //       col: 6
  //       // property: { attrs: { suffix: '°C', type: 'number' } }
  //     },
  //     rom_4: {
  //       widget: 'wtext',
  //       data: null,
  //       label: 'ROM 4',
  //       col: 6
  //       // property: { attrs: { suffix: '°C', type: 'number' } }
  //     },
  //     ket_rom: {
  //       widget: 'wtextarea',
  //       data: null,
  //       label: 'Keterangan ROM',
  //       col: 12
  //       // property: { attrs: { suffix: '/ mmHg' } }
  //     }
  //   }
  // }
}
